const monitoring = {
  executionsMonitoringStatus: 'Statut',
  executionsMonitoringCreatedAt: 'Date de création',
  executionsMonitoringResources: 'Ressources',
  executionsMonitoringExecutionId: "ID d'exécution",
  executionsMonitoringCreationDate: 'Date de création',
  executionsMonitoringEndDate: 'Date de fin',

  executionDetails: "Détails de l'exécution",
  listOfActions: 'Liste des actions',

  actionResource: 'Ressource',
  actionStartDate: 'Date de début',
  actionEndDate: 'Date de fin',
  actionStatus: 'Statut',
  actionType: "Type d'action",
  actionOptions: 'Options',

  executionStatusNothingToDo: 'Aucune action',
  executionStatusPending: 'En attente',
  executionStatusInProgress: 'En cours',
  executionStatusCompleted: 'Terminé',
  executionStatusFailed: 'Échoué',

  actionStatusPending: 'En attente',
  actionStatusSkipped: 'Ignoré',
  actionStatusInProgress: 'En cours',
  actionStatusFailed: 'Échoué',
  actionStatusCompleted: 'Terminé',

  actionCopyResourceId: "Copier l'ID de la resource",
  actionSeeLogs: 'Voir les logs',
  actionLogsTitle: 'Logs',

  sourceActionInitializeSource: 'Initialize source',
  sourceActionFetchNewSourceData: 'Fetch new source data',
  sourceActionComputeSource: 'Compute source',
  datasetActionInitializeDataset: 'Initialize dataset',
  datasetActionIntegrateSourceDataToDataset: 'Integrate source data to dataset',
  datasetActionComputeDataset: 'Compute dataset',
  syncActionDeliverAllSyncRecords: 'Deliver all sync records',
  syncActionDeliverSyncRecordsIncrement: 'Deliver sync records increment',
  syncActionDeliverSpecificSyncRecords: 'Deliver specific sync records',

  analyticsSyncActionDeliverDatasetRecordsToAnalytics: 'Deliver dataset records to analytics',

  garbageCollectionActionCleanUpDataWarehouseGarbage: 'Clean up data warehouse garbage',
  garbageCollectionActionCleanUpExecutionsGarbage: 'Clean up executions garbage',
  accountResourcesGarbageCollectionResourceName: 'Garbage Collection',

  resources: 'Resources',
};

export default monitoring;
